<template>
  <div
    class="assignment-task"
    :class="{
      'highlighted': highlighted,
      'failed': taskFailed
    }"
    @mouseover="onMouseOver"
    @mouseout="onMouseOut"
    @click="onClick"
    @contextmenu="$emit('task-contextmenu', $event, task)">
    <div
      v-if="mapMarkerVisible"
      class="task-map-marker">
      <timeslot-map-marker
        :id="mapMarkerId"
        :startTime="task.startTime"/>
    </div>
    <div class="task-info">
      <div
        v-if="removable"
        class="remove-task"
        @click.stop="removeTask">
        <b-icon
          icon="trash"
          size="is-small"/>
      </div>
      <task-status
        v-else-if="statusVisible"
        :task="task"/>
      <b-tag
        v-else-if="taskIncoming"
        type="is-primary assign-pickupp-button"
        @click.native.stop="$emit('show-pickupp-form', task)">
        {{ $t(`assignment.leftPanel.task.${externalLogisticsType === 'lalamove' ? 'assignLalamove' : 'assignPickupp'}`) }}
      </b-tag>
      <div class="fulfilment-timeslot">
        {{ task.rangeLabel }}
      </div>
      <div class="fulfilment-address">
        {{ task.deliveryAddress || task.pickupPointName }}
      </div>
      <div class="task-details">
        <template v-if="task.externalLogisticsId">
          <span class="task-3pl-short-id">
            {{ $t(`common.task.shortId.${task.externalLogisticsType || 'pickupp'}`, { shortId: task.shortId }) }}
          </span>
          •
        </template>
        <span
          class="task-order-number"
          :class="getOrderTypeClass(task)">
          {{ task.orderIdentifier }}
        </span>
        •
        <span class="customer-name">
          {{ task.customerName }}
        </span>
        •
        <span class="customer-contact-number">
          {{ task.customerContactNumber }}
        </span>
      </div>
      <b-tag
        v-if="task.deliveryFeeOverrideType === 'premium'"
        type="is-warning premium-delivery-tag">
        {{ $t('assignment.leftPanel.task.premiumDelivery') }}
      </b-tag>
      <b-tag
        v-if="parseFloat(task.weight) > 20"
        type="overweight-tag">
        {{ $t('assignment.leftPanel.task.overweight') }}
      </b-tag>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TASK_STATES } from '@js/constants'
import { isTripDispatched } from '@js/trip-utils'

const TaskStatus = () => import('@components/TaskStatus')
const TimeslotMapMarker = () => import('@components/TimeslotMapMarker')

export default {
  name: 'assignment-task',
  components: {
    TaskStatus,
    TimeslotMapMarker
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    mapMarkerVisible: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    removable: {
      type: Boolean,
      default: false
    },
    statusVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('sessionUser', [
      'features'
    ]),
    ...mapGetters('task', [
      'highlightedTask'
    ]),
    ...mapGetters('timeslot', [
      'getTimeslotIdByStartTime'
    ]),
    ...mapGetters('trip', [
      'assigningTripTasks',
      'selectedAssigningTrip',
      'tripsTasks'
    ]),
    externalLogisticsType() {
      if (this.features.useLalamove) {
        return 'lalamove'
      }
      return 'pickupp'
    },
    highlighted() {
      return this.highlightedTask && this.highlightedTask.id === this.task.id
    },
    taskIncoming() {
      return this.task.state === TASK_STATES.INCOMING
    },
    taskFailed() {
      return this.task.state === TASK_STATES.FAILED
    },
    mapMarkerId() {
      return this.getTimeslotIdByStartTime(this.task.startTime)
    }
  },
  methods: {
    ...mapActions('task', [
      'setHighlightedTask'
    ]),
    ...mapActions('trip', [
      'addTripTask',
      'removeTripTask'
    ]),
    onMouseOver() {
      this.setHighlightedTask(this.task)
    },
    onMouseOut() {
      if (this.highlightedTask && this.highlightedTask.id === this.task.id) {
        this.setHighlightedTask(null)
      }
    },
    onClick() {
      if (!this.selectable) return
      if (this.selectedAssigningTrip && isTripDispatched(this.selectedAssigningTrip)) {
        return this.$buefy.dialog.alert({
          message: this.$t('assignment.rightPanel.assign.error.tripStarted')
        })
      }

      if (!this.assigningTripTasks.find(tripTask => tripTask.id === this.task.id)) {
        this.addTripTask(this.task)
      }
    },
    removeTask() {
      // show remove confirmation for assigned task
      if (this.tripsTasks.find(task => task.id === this.task.id)) {
        if (this.task.externalLogisticsId) {
          return this.$emit('show-strong-confirmation', {
            onConfirm: () => this.showRemoveTaskConfirmation()
          })
        }
        this.showRemoveTaskConfirmation()
      } else {
        this.removeTripTask(this.task)
        this.onMouseOut()
      }
    },
    showRemoveTaskConfirmation() {
      this.$buefy.dialog.confirm({
        title: this.$t('assignment.rightPanel.assign.task.remove.title'),
        message: this.$t('assignment.rightPanel.assign.task.remove.message'),
        confirmText: this.$t('assignment.rightPanel.assign.task.remove.confirm'),
        cancelText: this.$t('assignment.rightPanel.assign.task.remove.cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.removeTripTask(this.task)
      })
    },
    getOrderTypeClass(task) {
      // use same color for admin orders with 30-minute time slots
      if ((task.endTime || 0) - (task.startTime || 0) === 1800) {
        return 'order-type-standard'
      }
      if (task.orderIdentifier.match(/GA\d+/)) {
        return 'order-type-admin'
      }
      if (task.orderIdentifier.match(/GM\d+/)) {
        return 'order-type-meal-plan'
      }
      return 'order-type-standard'
    }
  }
}
</script>

<style lang="scss" scoped>
.assignment-task {
  display: flex;
  padding-top: $space-xs;
  padding-right: $space-xs;
  padding-left: $space-xs;
  cursor: pointer;

  &:not(:last-child) {
    .task-info {
      border-bottom: 1px solid $grey-dark;
    }
  }

  &:hover,
  &.highlighted {
    background-color: $grey-lighter;

    .fulfilment-timeslot {
      color: $black;
    }

    .task-details {
      color: $grey-darker;
    }
  }

  &.failed {
    background-color: $danger-light;
  }

  &:not(:hover) {
    .remove-task {
      display: none;
    }
  }

  > *:not(:last-child) {
    padding-right: $space-xs;
  }

  .task-info {
    flex-grow: 1;
    padding-bottom: $space-xs;

    .remove-task,
    .task-status {
      float: right;
      cursor: pointer;
    }

    .remove-task {
      color: $grey-darker;
      cursor: pointer;
    }

    .assign-pickupp-button {
      float: right;
      height: 20px;
      line-height: 20px;
      cursor: pointer;
    }

    .fulfilment-address {
      padding-top: $space-xxs;
      padding-bottom: $space-xxs;
      font-weight: bold;
      color: $grey-dark;
      @extend %small;
    }
  }

  .task-details {
    color: $grey-light;
    @extend %small;

    .task-order-number {
      font-weight: bold;

      &.order-type-admin {
        color: #00b2ee;
      }

      &.order-type-meal-plan {
        color: #52c41a;
      }

      &.order-type-standard {
        color: #da70d6;
      }
    }
  }

  .premium-delivery-tag,
  .overweight-tag {
    height: 16px;
    padding: 0 6px;
    font-size: 11px;
    font-weight: bold;
    border-radius: 2px;

    &:not(:last-child) {
      margin-right: $space-xxs;
    }
  }

  .overweight-tag {
    color: rgba($black, 0.7);
    background-color: #ff9f47;
  }
}
</style>
